var swipe = {
	init: function () {

		var swiper = new Swiper('.swiper-container', {
			loop: true,
			// effect: "flip",
			autoplay: {
				delay: 5000,
			},
			// pagination: {
			// 	el: '.swiper-pagination',
			// 	clickable: true,
			// },
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			// Default parameters for smallest screen
			slidesPerView: 1
			// spaceBetween: 0,
			// // Responsive breakpoints
			// breakpointsInverse: true,
			// 	breakpoints: {
			// 	// when window width is >= 768px
			// 	600: {
			// 	  slidesPerView: 3,
			// 	  spaceBetween: 20
			// 	},				// when window width is >= 768px
			// 	768: {
			// 	  slidesPerView: 4,
			// 	  spaceBetween: 20
			// 	},
			// 	// when window width is >= 1024px
			// 	1024: {
			// 	  slidesPerView: 5,
			// 	  spaceBetween: 40
			// 	},
			// 	// when window width is >= 1200px
			// 	1200: {
			// 	  slidesPerView: 7,
			// 	  spaceBetween: 40
			// 	},
			// 	// when window width is >= 1600px
			// 	1600: {
			// 	  slidesPerView: 9,
			// 	  spaceBetween: 40
			// 	}
			// }
		});
	}
};

swipe.init();